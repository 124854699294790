import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Stack } from '@chakra-ui/react';

type ChangeHandler = (isMale: boolean, isFemale: boolean) => void;

interface GenderCheckboxProps {
  onChange: ChangeHandler;
}

const GenderCheckbox = forwardRef<HTMLInputElement, GenderCheckboxProps>(
  function GenderCheckbox({ onChange }: GenderCheckboxProps, ref) {
    const { t } = useTranslation();
    const [isFemaleChecked, setIsFemaleChecked] = useState(true);
    const [isMaleChecked, setIsMaleChecked] = useState(true);

    const handleFemaleChange = (isChecked: boolean) => {
      setIsFemaleChecked(isChecked);
      onChange(isMaleChecked, isChecked);
    };

    const handleMaleChange = (isChecked: boolean) => {
      setIsMaleChecked(isChecked);
      onChange(isChecked, isFemaleChecked);
    };

    return (
      <Box
        m={10}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        fontSize={'lg'}
        textStyle="sm"
        color="fg.muted"
      >
        <Stack spacing={10} direction="row">
          <Checkbox
            id="female-checkbox"
            variant="black"
            defaultChecked={isFemaleChecked}
            onChange={() => handleFemaleChange(!isFemaleChecked)}
            ref={ref}
          >
            {t('calculator.gender.female')}
          </Checkbox>
          <Checkbox
            id="male-checkbox"
            variant="black"
            defaultChecked={isMaleChecked}
            onChange={() => handleMaleChange(!isMaleChecked)}
          >
            {t('calculator.gender.male')}
          </Checkbox>
        </Stack>
      </Box>
    );
  },
);

export const genderCheckboxRef = React.createRef<HTMLInputElement>(); // Using React.createRef instead of useRef

export default GenderCheckbox;
