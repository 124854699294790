import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Stack } from '@chakra-ui/react';

type ChangeHandler = (selectedMarriageStatus: string[]) => void;

interface MarriageStatusCheckboxProps {
  onChange: ChangeHandler;
}

const maritalStatusNames = ['any', 'neverMarried', 'separated', 'married'];

const selectedMarriageStatus = (booleanList: boolean[]) =>
  booleanList.reduce((acc: string[], value, index) => {
    if (value) {
      acc.push(maritalStatusNames[index]);
    }
    return acc;
  }, []);

export default function MaritalStatusCheckbox({
  onChange,
}: MarriageStatusCheckboxProps) {
  const [checkedItems, setCheckedItems] = useState([true, true, true, true]);

  const allChecked = checkedItems.every(Boolean);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const handleCheckboxChange = (index: number) => {
    if (index === 0) {
      // If the "any" checkbox is clicked
      if (!isIndeterminate) {
        // If "any" is true, clear all other checkboxes
        const newCheckedItems = checkedItems.map((_, i) =>
          i === 0 ? true : false,
        );
        setCheckedItems(newCheckedItems);
        setIsIndeterminate(true);
        onChange(selectedMarriageStatus(newCheckedItems));
      } else {
        // If "any" is false, tick all checkboxes
        const newCheckedItems = Array(checkedItems.length).fill(true);
        setCheckedItems(newCheckedItems);
        setIsIndeterminate(false);
        onChange(selectedMarriageStatus(newCheckedItems));
      }
    } else {
      // If any other checkbox is clicked, toggle its value
      const updatedItems = [...checkedItems];
      updatedItems[index] = !updatedItems[index];
      setCheckedItems(updatedItems);
      setIsIndeterminate(
        updatedItems.some(Boolean) && !updatedItems.every(Boolean),
      );
      onChange(selectedMarriageStatus(updatedItems));
    }
  };

  const { t } = useTranslation();

  return (
    <Box
      m={10}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      fontSize={'lg'}
      textStyle="sm"
      color="fg.muted"
    >
      <Checkbox
        id="any-marital-status-checkbox"
        isChecked={allChecked}
        isIndeterminate={isIndeterminate}
        onChange={() => handleCheckboxChange(0)}
        variant="black"
      >
        {t('calculator.maritalStatus.any')}
      </Checkbox>
      <Stack pl={6} mt={1} spacing={1}>
        <Checkbox
          id="never-married-checkbox"
          isChecked={checkedItems[1]}
          onChange={() => handleCheckboxChange(1)}
          variant="black"
        >
          {t('calculator.maritalStatus.neverMarried')}
        </Checkbox>
        <Checkbox
          id="separated-checkbox"
          isChecked={checkedItems[2]}
          onChange={() => handleCheckboxChange(2)}
          variant="black"
        >
          {t('calculator.maritalStatus.separated')}
        </Checkbox>
        <Checkbox
          id="married-checkbox"
          isChecked={checkedItems[3]}
          onChange={() => handleCheckboxChange(3)}
          variant="black"
        >
          {t('calculator.maritalStatus.married')}
        </Checkbox>
      </Stack>
    </Box>
  );
}
