import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  XIcon,
} from 'react-share';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Skeleton,
  Spacer,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { motion, useAnimation } from 'framer-motion';
import { formatProbability } from '../../utils/formatProbability';
import HexagonGrid from '../Hexagon/HexagonGrid';
import { TopConfetti } from '../LandingPage/Confetti';
import { PopulationEstimateText } from '../PopulationEstimateText/PopulationEstimateText';
import { MAX_AGE_VALUE } from '../Slider/AgeSlider';
import { MAX_INCOME_VALUE } from '../Slider/IncomeSlider';
import { Stat } from '../Stat/Stat';
import { convertToShortScale } from './utils';

const SHARE_URL = 'https://www.testmystandard.com/';
const HASH_TAG = 'TestMyStandardJP';

// TODO: validate response format
type ResultResponse = {
  data: {
    probability: number;
    minAnnualIncome: number;
    maxAnnualIncome: number;
    isFemale: boolean;
    isMale: boolean;
    isNeverMarried: boolean;
    isMarried: boolean;
    isSeparated: boolean;
    minAge: number;
    maxAge: number;
    minHeight: number;
    maxHeight: number;
    regions: string[];
    sexProbability: number;
    regionProbability: number;
    sex: {
      probability: number;
      wholePopulationProbability: number;
      femaleProbability: number;
      maleProbability: number;
    };
    income: {
      probability: number;
      wholePopulationProbability: number;
      femaleProbability: number;
      maleProbability: number;
    };
    age: {
      probability: number;
      wholePopulationProbability: number;
      femaleProbability: number;
      maleProbability: number;
    };
    height: {
      probability: number;
      wholePopulationProbability: number;
      femaleProbability: number;
      maleProbability: number;
    };
    region: {
      probability: number;
      wholePopulationProbability: number;
      femaleProbability: number;
      maleProbability: number;
    };
    maritalStatus: {
      probability: number;
      wholePopulationProbability: number;
      femaleProbability: number;
      maleProbability: number;
    };
  };
};

// Utility type to exclude 'location' prop from ResultProps
type OmitLocation<T> = Omit<T, 'location'>;

interface ResultProps extends OmitLocation<React.HTMLProps<HTMLDivElement>> {}

export const Result: React.FC<ResultProps> = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const location = useLocation();
  const navigate = useNavigate();
  const [isSmallerThanMobile] = useMediaQuery('(max-width: 30em)');

  useEffect(() => {
    if (!location.state) {
      navigate('/');
    }
  }, [location, navigate]);

  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  // Access the state passed from the previous page
  const { data } = (location.state as ResultResponse) || { data: {} };
  console.log('debug data', data);
  const isMultipleRegion = data.regions && data.regions.length >= 3;

  if (!data.regions) {
    data.regions = [];
  }

  let regionText = '';
  if (
    data.regions.includes('japan') &&
    data.regions.includes('europe') &&
    data.regions.includes('america') &&
    data.regions.includes('africa') &&
    data.regions.includes('asia') &&
    data.regions.includes('northAmerica') &&
    data.regions.includes('southAmerica') &&
    data.regions.includes('oceania')
  ) {
    regionText = t('calculator.region.anywhere');
  } else {
    const regions = [];

    if (data.regions.includes('japan'))
      regions.push(t('calculator.region.japan'));
    if (data.regions.includes('asia'))
      regions.push(t('calculator.region.asia'));
    if (data.regions.includes('europe'))
      regions.push(t('calculator.region.europe'));
    if (data.regions.includes('africa'))
      regions.push(t('calculator.region.africa'));
    if (data.regions.includes('northAmerica'))
      regions.push(t('calculator.region.northAmerica'));
    if (data.regions.includes('southAmerica'))
      regions.push(t('calculator.region.southAmerica'));
    if (data.regions.includes('oceania'))
      regions.push(t('calculator.region.oceania'));

    regionText = regions.join(isMultipleRegion ? ', ' : '');
  }

  let regionFlag = '';
  if (data.regions.includes('japan') && data.regions.length === 2) {
    regionFlag = 'onlyDomestic';
  } else if (!data.regions.includes('japan')) {
    regionFlag = 'onlyForeign';
  } else {
    regionFlag = 'domesticAndForeign';
  }

  let maritalStatusText = '';
  const maritalStatuses = [];
  const maritalStatusflags = [
    data.isMarried,
    data.isSeparated,
    data.isNeverMarried,
  ];
  const trueCount = maritalStatusflags.filter((flag) => flag).length;
  let isMultipleMaritalStatus = false;
  if (trueCount > 1) {
    isMultipleMaritalStatus = true;
  }

  if (data.isNeverMarried) {
    maritalStatuses.push(t('calculator.maritalStatus.neverMarried'));
  }
  if (data.isMarried) {
    maritalStatuses.push(t('calculator.maritalStatus.married'));
  }
  if (data.isSeparated) {
    maritalStatuses.push(t('calculator.maritalStatus.separated'));
  }
  maritalStatusText = maritalStatuses.join(isMultipleMaritalStatus ? ', ' : '');

  const FloatingBox = ({ children }: { children: React.ReactNode }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
      if (inView) {
        controls.start({ y: 0, opacity: 1, transition: { duration: 1 } });
      }
    }, [controls, inView]);

    return <div ref={ref}>{children}</div>;
  };

  const FloatingImage = ({ imageUrl }: { imageUrl: string }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
      if (inView) {
        controls.start({ y: 0, opacity: 1, transition: { duration: 2 } });
      }
    }, [controls, inView]);

    return (
      <Flex justify="center">
        <motion.div
          ref={ref}
          initial={{ y: 50, opacity: 0 }}
          animate={controls}
          style={{ width: isSmallerThanMobile ? '50%' : '250px' }} // Adjust width based on screen size
        >
          <Image
            src={imageUrl}
            onLoad={handleImageLoad}
            style={{
              width: '100%',
              ...(imageLoaded ? {} : { display: 'none' }),
            }}
          />
        </motion.div>
      </Flex>
    );
  };

  return (
    <Box maxW={{ base: '80%', md: '500px' }} mx="auto" textAlign="center">
      <Spacer height={'6em'} />
      <Box display="flex" justifyContent="center">
        <FloatingBox>
          <Heading
            as="h2"
            size="xl"
            textAlign="center"
            mb={{ base: '2', md: '4' }}
          >
            {t('result.result')}
          </Heading>
          <Box px={{ base: '2', md: '6' }} py={{ base: '3', md: '5' }}>
            <Stack textAlign={'center'} spacing={4}>
              <Text textStyle="sm" color="fg.muted">
                {t('result.possibility')}
              </Text>
              <Text fontSize={{ base: '3xl', md: '5xl' }} as="b">
                {formatProbability(data.probability)} %
              </Text>
              <PopulationEstimateText probability={data.probability} />
            </Stack>
          </Box>
        </FloatingBox>
      </Box>
      <Flex justify="center">
        {!imageLoaded && <Skeleton boxSize={'200px'} />}
        {data.probability > 50 && <TopConfetti />}
        <FloatingImage
          imageUrl={
            data.probability > 90
              ? 'https://test-my-standard-assets.s3.ap-northeast-1.amazonaws.com/aitokyotower/aitokyotower%2B.png'
              : data.probability > 70
                ? 'https://test-my-standard-assets.s3.ap-northeast-1.amazonaws.com/aitokyotower/aitokyotower%2B3.png'
                : data.probability > 50
                  ? 'https://test-my-standard-assets.s3.ap-northeast-1.amazonaws.com/aitokyotower/aitokyotower%2B4.png'
                  : data.probability > 25
                    ? 'https://test-my-standard-assets.s3.ap-northeast-1.amazonaws.com/aitokyotower/aitokyotower%2B5.png'
                    : 'https://test-my-standard-assets.s3.ap-northeast-1.amazonaws.com/aitokyotower/aitokyotower%2B2.png'
          }
        />
      </Flex>
      <Spacer height={'4em'} />
      <Flex direction="column" alignItems="center">
        <HexagonGrid fillPercentage={data.probability} />
      </Flex>
      <Spacer height={'4em'} />
      <Flex direction="column" alignItems="center">
        <Box m={10}>
          <Flex justify="center">
            <Card align={'center'} size={'md'} maxW={'md'} variant={'unstyled'}>
              <CardHeader>
                <Heading size="xl">{t('result.summary.title')}</Heading>
              </CardHeader>
              <CardBody minW={'xs'}>
                <Stack>
                  <Box as="section" py={{ base: '4' }}>
                    <Container>
                      <SimpleGrid columns={{ base: 1, md: 1 }}>
                        <Stat
                          key={'age'}
                          label={t('result.age')}
                          attribute1={`${data.minAge} - ${
                            data.maxAge === MAX_AGE_VALUE ? '100+' : data.maxAge
                          }`}
                          attribute2={t(regionFlag)}
                          value1={data.age.wholePopulationProbability}
                          value2={data.age.femaleProbability}
                          value3={data.age.maleProbability}
                        />
                        <Stat
                          key={'height'}
                          label={t('result.height')}
                          attribute1={`${data.minHeight} - ${
                            data.maxHeight === 191
                              ? '190+'
                              : data.maxHeight.toString()
                          } cm`}
                          attribute2={undefined}
                          value1={data.height.wholePopulationProbability}
                          value2={data.height.femaleProbability}
                          value3={data.height.maleProbability}
                        />
                        <Stat
                          key={'income'}
                          label={t('result.income')}
                          attribute1={`${
                            currentLanguage === 'en'
                              ? t('unit.income-prefix')
                              : ''
                          }${convertToShortScale(
                            currentLanguage,
                            data.minAnnualIncome,
                          )} - ${
                            data.maxAnnualIncome === MAX_INCOME_VALUE
                              ? `${convertToShortScale(
                                  currentLanguage,
                                  data.maxAnnualIncome - 1,
                                )}+`
                              : convertToShortScale(
                                  currentLanguage,
                                  data.maxAnnualIncome,
                                )
                          } ${
                            currentLanguage === 'en'
                              ? ''
                              : t('unit.income-suffix')
                          }`}
                          attribute2={undefined}
                          value1={data.income.wholePopulationProbability}
                          value2={data.income.femaleProbability}
                          value3={data.income.maleProbability}
                        />
                        <Stat
                          key={'region'}
                          label={t('result.region')}
                          attribute1={regionText}
                          attribute2={`${data.minAge} - ${
                            data.maxAge === MAX_AGE_VALUE ? '100+' : data.maxAge
                          }`}
                          value1={data.region.wholePopulationProbability}
                          value2={data.region.femaleProbability}
                          value3={data.region.maleProbability}
                        />
                        <Stat
                          key={'maritalStatus'}
                          label={t('result.maritalStatus')}
                          attribute1={maritalStatusText}
                          attribute2={undefined}
                          value1={data.maritalStatus.wholePopulationProbability}
                          value2={data.maritalStatus.femaleProbability}
                          value3={data.maritalStatus.maleProbability}
                        />
                      </SimpleGrid>
                    </Container>
                  </Box>
                </Stack>
              </CardBody>
            </Card>
          </Flex>
        </Box>
      </Flex>
      <Spacer height={'4em'} />
      <Box
        m={10}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        fontWeight="semibold"
      >
        <Button
          id="test-again-button"
          aria-label="Test Again Button"
          rightIcon={<ArrowForwardIcon />}
          variant="black"
          fontWeight="semibold"
          onClick={() => {
            navigate('/calculator');
          }}
          mb={20}
        >
          {t('result.again')}
        </Button>
        <Box
          display={'flex'}
          justifyItems={'center'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Text>{t('result.shareYourResult')}</Text>
          <Box mt={10} mb={12}>
            <TwitterShareButton
              title={`${t('result.shareTitle')} ${data.probability}%`}
              url={SHARE_URL}
              hashtags={[HASH_TAG]}
            >
              <XIcon size={36} round />
            </TwitterShareButton>
            <FacebookShareButton
              url={SHARE_URL}
              hashtag={HASH_TAG}
              style={{ marginLeft: '10px' }}
            >
              <FacebookIcon size={36} round />
            </FacebookShareButton>
            <EmailShareButton
              url={SHARE_URL}
              subject={t('result.shareEmailTitle')}
              body={`${t('result.shareTitle')} ${data.probability}%. \n\n`}
              style={{ marginLeft: '10px' }}
            >
              <EmailIcon size={36} round />
            </EmailShareButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

Result.displayName = 'Result';

export default Result;
