import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Divider,
  FormControl,
  FormErrorMessage,
  Spacer,
  Text,
} from '@chakra-ui/react';
import GenderCheckbox, {
  genderCheckboxRef,
} from '../GenderCheckbox/GenderCheckbox';
import MaritalStatusCheckbox from '../MaritalStatusCheckbox/MaritalStatusCheckbox';
import RegionCheckbox from '../RegionCheckbox/RegionCheckbox';
import AgeSlider, { MAX_AGE_VALUE, MIN_AGE_VALUE } from '../Slider/AgeSlider';
import HeightSlider, {
  MAX_HEIGHT_VALUE,
  MIN_HEIGHT_VALUE,
} from '../Slider/HeightSlider';
import IncomeSlider, {
  MAX_INCOME_VALUE,
  MIN_INCOME_VALUE,
} from '../Slider/IncomeSlider';
import './scroll.css';

interface FormData {
  isMale: boolean;
  isFemale: boolean;
  maxAge: number;
  minAge: number;
  maxHeight: number;
  minHeight: number;
  maxAnnualIncome: number;
  minAnnualIncome: number;
  regions: string[];
  maritalStatuses: string[];
  isNeverMarried: boolean;
  isMarried: boolean;
  isSeparated: boolean;
}

// TODO: env variable
const url = 'https://cr7yjdzye7.execute-api.us-east-1.amazonaws.com/prod/data';

export const Calculator = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const [formData, setFormData] = useState({
    isMale: true,
    isFemale: true,
    maxAge: MAX_AGE_VALUE,
    minAge: MIN_AGE_VALUE,
    maxHeight: MAX_HEIGHT_VALUE,
    minHeight: MIN_HEIGHT_VALUE,
    maxAnnualIncome: MAX_INCOME_VALUE,
    minAnnualIncome: MIN_INCOME_VALUE,
    regions: [
      'anywhere',
      'japan',
      'asia',
      'europe',
      'africa',
      'northAmerica',
      'southAmerica',
      'oceania',
    ],
    maritalStatuses: ['any', 'neverMarried', 'married', 'separated'],
    isNeverMarried: true,
    isMarried: true,
    isSeparated: true,
  });

  useEffect(() => {
    validateForm(formData);
  }, [formData]);

  const validateForm = (formData: FormData) => {
    const newErrors: string[] = [];

    if (!formData.isFemale && !formData.isMale) {
      newErrors.push('error.sexValidation');
    }

    if (formData.regions.length === 1 && formData.regions[0] === 'anywhere') {
      newErrors.push('error.regionValidation');
    }

    if (
      formData.maritalStatuses.length === 1 &&
      formData.maritalStatuses[0] === 'any'
    ) {
      newErrors.push('error.maritalStatusesValidation');
    }

    setErrors(newErrors);
  };

  const handleGenderChange = (isMale: boolean, isFemale: boolean) => {
    const updatedFormData = { ...formData, isMale, isFemale };
    setFormData(updatedFormData);
    validateForm(updatedFormData);
  };

  const handleAgeChange = (minAge: number, maxAge: number) => {
    const updatedFormData = { ...formData, minAge, maxAge };
    setFormData(updatedFormData);
    validateForm(updatedFormData);
  };

  const handleHeightChange = (minHeight: number, maxHeight: number) => {
    const updatedFormData = { ...formData, minHeight, maxHeight };
    setFormData(updatedFormData);
    validateForm(updatedFormData);
  };

  const handleIncomeChange = (
    minAnnualIncome: number,
    maxAnnualIncome: number,
  ) => {
    const updatedFormData = { ...formData, minAnnualIncome, maxAnnualIncome };
    setFormData(updatedFormData);
    validateForm(updatedFormData);
  };

  const handleRegionChange = (selectedRegions: string[]) => {
    const updatedFormData = { ...formData, regions: selectedRegions };
    setFormData(updatedFormData);
    validateForm(updatedFormData);
  };

  const handleMarriageStatusChange = (selectedStatuses: string[]) => {
    let isNeverMarried = true;
    let isMarried = true;
    let isSeparated = true;

    if (!selectedStatuses.includes('married')) {
      isMarried = false;
    }
    if (!selectedStatuses.includes('separated')) {
      isSeparated = false;
    }
    if (!selectedStatuses.includes('neverMarried')) {
      isNeverMarried = false;
    }

    const updatedFormData = {
      ...formData,
      maritalStatuses: selectedStatuses,
      isMarried,
      isSeparated,
      isNeverMarried,
    };
    setFormData(updatedFormData);
    validateForm(updatedFormData);
  };

  const navigate = useNavigate();

  const handleButtonClick = async () => {
    if (errors.length === 0) {
      setIsLoading(true);
      try {
        // Make API request
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        // Parse the JSON response
        const resultData = await response.json();

        navigate('/result', {
          state: { data: resultData, from: 'calculator' },
        });
      } catch (error) {
        console.error('Error fetching data:', error);
        // TODO: Handle error (e.g., show an error message to the user)
      } finally {
        setIsLoading(false);
      }
    }

    // Debug console
    console.log(`formData: ${JSON.stringify(formData)}`);
  };

  return (
    <Box
      maxW={{ base: '100%', md: '550px', lg: '90%' }}
      mx="auto"
      px={{ base: '2', md: '6' }}
    >
      <Spacer height={'70px'} />
      <Box position="relative" padding="10">
        <Box
          bg="transparent"
          px="8"
          py="4"
          whiteSpace="nowrap"
          fontWeight={'true'}
          fontSize={'lg'}
          color="fg.muted"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            fontWeight={'bold'}
            fontSize={{ base: '1xl', md: '2xl' }}
            textStyle="lg"
          >
            {t('calculator.genderQuestion')}
          </Text>
        </Box>
        <FormControl isInvalid={errors.includes('error.sexValidation')}>
          <GenderCheckbox
            ref={genderCheckboxRef}
            onChange={handleGenderChange}
          ></GenderCheckbox>
          <Center>
            <FormErrorMessage>{t('error.sexValidation')}</FormErrorMessage>
          </Center>
        </FormControl>
      </Box>
      <Divider></Divider>
      <Spacer height={'4em'}></Spacer>
      <Box padding="10">
        <Box
          bg="transparent"
          px="8"
          py="4"
          whiteSpace="nowrap"
          fontSize={'lg'}
          textStyle="sm"
          color="fg.muted"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            fontWeight={'bold'}
            fontSize={{ base: '1xl', md: '2xl' }}
            textStyle="lg"
          >
            {t('calculator.ageQuestion')}
          </Text>
        </Box>
      </Box>
      <AgeSlider
        max={MAX_AGE_VALUE}
        min={MIN_AGE_VALUE}
        stepByNumber={1}
        defaultValue={[MIN_AGE_VALUE, MAX_AGE_VALUE]}
        aria-label={['age1', 'age2']}
        onChange={handleAgeChange}
      ></AgeSlider>
      <Spacer height={'4em'}></Spacer>
      <Divider></Divider>
      <Box position="relative" padding="10">
        <Box
          bg="transparent"
          px="8"
          py="4"
          whiteSpace="nowrap"
          fontSize={'lg'}
          textStyle="sm"
          color="fg.muted"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            fontWeight={'bold'}
            fontSize={{ base: '1xl', md: '2xl' }}
            textStyle="lg"
          >
            {t('calculator.heightQuestion')}
          </Text>
        </Box>
      </Box>
      <HeightSlider
        max={MAX_HEIGHT_VALUE}
        min={MIN_HEIGHT_VALUE}
        stepByNumber={1}
        defaultValue={[MIN_HEIGHT_VALUE, MAX_HEIGHT_VALUE]}
        aria-label={['height1', 'height2']}
        onChange={handleHeightChange}
      ></HeightSlider>
      <Spacer height={'4em'}></Spacer>
      <Divider></Divider>
      <Box position="relative" padding="10">
        <Box
          bg="transparent"
          px="8"
          py="4"
          whiteSpace="nowrap"
          fontSize={'lg'}
          textStyle="sm"
          color="fg.muted"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            fontWeight={'bold'}
            fontSize={{ base: '1xl', md: '2xl' }}
            textStyle="lg"
          >
            {t('calculator.incomeQuestion')}
          </Text>
        </Box>
      </Box>
      <IncomeSlider
        max={MAX_INCOME_VALUE}
        min={MIN_INCOME_VALUE}
        stepByNumber={100}
        defaultValue={[MIN_INCOME_VALUE, MAX_INCOME_VALUE]}
        aria-label={['income1', 'income2']}
        onChange={handleIncomeChange}
      ></IncomeSlider>
      <Spacer height={'6em'}></Spacer>
      <Divider></Divider>
      <Box position="relative">
        <Spacer height={'6em'}></Spacer>
        <Box
          bg="transparent"
          px="8"
          py="4"
          whiteSpace="nowrap"
          fontSize={'lg'}
          textStyle="sm"
          color="fg.muted"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            fontWeight={'bold'}
            fontSize={{ base: '1xl', md: '2xl' }}
            textStyle="lg"
          >
            {t('calculator.regionQuestion')}
          </Text>
        </Box>
      </Box>
      <Box
        position="relative"
        padding="10"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <FormControl isInvalid={errors.includes('error.regionValidation')}>
          <RegionCheckbox onChange={handleRegionChange}></RegionCheckbox>
          <Center>
            <FormErrorMessage>{t('error.regionValidation')}</FormErrorMessage>
          </Center>
        </FormControl>
      </Box>
      <Spacer height={'4em'}></Spacer>
      <Divider></Divider>
      <Spacer height={'4em'}></Spacer>
      <Box position="relative">
        <Box
          bg="transparent"
          px="8"
          py="4"
          whiteSpace="nowrap"
          fontSize={'lg'}
          textStyle="sm"
          color="fg.muted"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            fontWeight={'bold'}
            fontSize={{ base: '1xl', md: '2xl' }}
            textStyle="lg"
          >
            {t('calculator.marriageQuestion')}
          </Text>
        </Box>
      </Box>
      <Box
        position="relative"
        padding="10"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <FormControl
          isInvalid={errors.includes('error.maritalStatusesValidation')}
        >
          <MaritalStatusCheckbox
            onChange={handleMarriageStatusChange}
          ></MaritalStatusCheckbox>
          <Center>
            <FormErrorMessage>
              {t('error.maritalStatusesValidation')}
            </FormErrorMessage>
          </Center>
        </FormControl>
      </Box>
      <Spacer height={'4em'}></Spacer>
      <Box
        position="relative"
        padding="10"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          isLoading={isLoading}
          id="calculate-result-button"
          aria-label="Calculate Result Button"
          disabled={errors.length !== 0}
          rightIcon={<ArrowForwardIcon />}
          variant={errors.length !== 0 ? undefined : 'black'}
          fontWeight="semibold"
          onClick={handleButtonClick}
        >
          {t('calculator.testMyStandardButton')}
        </Button>
      </Box>
      {errors.length > 0 && (
        <Box color="red" textAlign="center" fontSize={'sm'}>
          {t('error.formValidation')}
        </Box>
      )}
    </Box>
  );
};
