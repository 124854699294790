import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Button, Spacer, Text } from '@chakra-ui/react';
import LoadingPage from '../LoadingPage/LoadingPage';
import TopTowerImage from '../TopTowerImage/TopTowerImage';
import { TopConfetti } from './Confetti';
import './scroll.css';

export const LandingPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleButtonClick = async () => {
    navigate('/calculator');
  };

  const [imagesLoaded, setImagesLoaded] = useState(0);
  const [imageUrls] = useState([
    'https://test-my-standard-assets.s3.ap-northeast-1.amazonaws.com/aitokyotower/aitokyotower%2B2.png',
    'https://test-my-standard-assets.s3.ap-northeast-1.amazonaws.com/aitokyotower/aitokyotower%2B5.png',
    'https://test-my-standard-assets.s3.ap-northeast-1.amazonaws.com/aitokyotower/aitokyotower%2B4.png',
    'https://test-my-standard-assets.s3.ap-northeast-1.amazonaws.com/aitokyotower/aitokyotower%2B3.png',
    'https://test-my-standard-assets.s3.ap-northeast-1.amazonaws.com/aitokyotower/aitokyotower%2B.png',
  ]);

  useEffect(() => {
    const handleImageLoad = () => {
      setImagesLoaded((prev) => prev + 1);
    };

    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
      img.onload = handleImageLoad;
    });
  }, [imageUrls]);

  if (imagesLoaded < imageUrls.length) {
    return <LoadingPage />;
  }

  return (
    <div
      style={{
        minHeight: 'calc(100vh - 150px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        backgroundColor={'white'}
        style={{ flexGrow: 1 }}
        backgroundSize="400% 400%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <TopConfetti />
        <Spacer height={'20px'} />
        <TopTowerImage imageUrls={imageUrls} />
        <Box
          position="absolute"
          top="50%"
          transform="translateY(-50%)"
          textAlign="center"
          width="100%"
        >
          <Box
            backgroundColor="rgba(255, 255, 255, 0.8)"
            p="4"
            borderRadius="md"
            mt="4"
          >
            <Text
              align={'center'}
              fontSize={{ base: '5xl', md: '8xl' }}
              textStyle="lg"
              color="fg.muted"
              fontWeight="bold"
            >
              {t('header.title')}
            </Text>
          </Box>
          <Box
            backgroundColor="rgba(255, 255, 255, 0.8)"
            p="4"
            borderRadius="md"
            mt="4"
          >
            <Text
              align={'center'}
              fontSize={{ base: 'sm', md: '1xl' }}
              textStyle="lg"
              color="fg.muted"
            >
              {t('calculator.topQuestion')}
            </Text>
          </Box>
        </Box>
        <Box
          position="relative"
          padding="1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            id="start-button"
            aria-label="Start Button"
            isLoading={false}
            rightIcon={<ArrowForwardIcon />}
            variant="black"
            fontWeight="semibold"
            onClick={handleButtonClick}
          >
            {t('calculator.startTestButton')}
          </Button>
        </Box>
        <Spacer height={'1em'}></Spacer>
      </Box>
    </div>
  );
};
