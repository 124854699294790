import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga';
import App from './App';
import './i18n/configs';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Initialize Google Analytics with your tracking ID
ReactGA.initialize('G-B61M0C82PS');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Track page view when the app is loaded
ReactGA.pageview(window.location.pathname + window.location.search);
