import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spacer,
  chakra,
  useRangeSlider,
} from '@chakra-ui/react';
import Thumb from './Thumb';

type ChangeHandler = (minAge: number, maxAge: number) => void;

type Props = {
  min: number;
  max: number;
  stepByNumber: number;
  defaultValue: [number, number];
  'aria-label': [string, string];
  children?: React.ReactNode;
  onChange: ChangeHandler;
};

export const MIN_AGE_VALUE = 18;
export const MAX_AGE_VALUE = 101;

export default function AgeSlider({
  min,
  max,
  stepByNumber,
  defaultValue,
  onChange,
  ...rest
}: Props) {
  const {
    state,
    actions,
    getInnerTrackProps,
    getInputProps,
    getRootProps,
    getThumbProps,
    getTrackProps,
  } = useRangeSlider({ min, max, defaultValue, ...rest });

  const { onKeyDown: onThumbKeyDownFirstIndex, ...thumbPropsFirstIndex } =
    getThumbProps({
      index: 0,
    });

  const { onKeyDown: onThumbKeyDownSecondIndex, ...thumbPropsSecondIndex } =
    getThumbProps({
      index: 1,
    });

  const onKeyDownStepBy = (
    e: React.KeyboardEvent<HTMLDivElement>,
    thumbIndex: number,
  ) => {
    if (e.code === 'ArrowRight') actions.stepUp(thumbIndex, stepByNumber);
    else if (e.code === 'ArrowLeft') actions.stepDown(thumbIndex, stepByNumber);
    else if (thumbIndex === 0 && typeof onThumbKeyDownFirstIndex === 'function')
      onThumbKeyDownFirstIndex(e);
    else if (
      thumbIndex === 1 &&
      typeof onThumbKeyDownSecondIndex === 'function'
    )
      onThumbKeyDownSecondIndex(e);
  };

  useEffect(() => {
    onChange(state.value[0], state.value[1]);
  }, [state.value[0], state.value[1]]);

  const handleNumberInputChange = (index: number, valueAsNumber: number) => {
    if (valueAsNumber > 100) {
      actions.setValueAtIndex(index, MAX_AGE_VALUE);
    } else {
      actions.setValueAtIndex(index, valueAsNumber);
    }
  };

  return (
    <Box m={'10'}>
      <chakra.div
        mt={2}
        cursor="pointer"
        w={{ base: '60%', lg: '40%' }}
        ml={{ base: '20%', lg: '30%' }}
        {...getRootProps()}
      >
        <input {...getInputProps({ index: 0 })} hidden />
        <input {...getInputProps({ index: 1 })} hidden />
        <Box h="7px" bgColor="grey" borderRadius="full" {...getTrackProps()}>
          <Box
            h="7px"
            bgColor="black"
            borderRadius="full"
            {...getInnerTrackProps()}
          />
        </Box>
        <Thumb
          value={state.value[0]}
          thumbIndex={0}
          thumbProps={thumbPropsFirstIndex}
          onKeyDownStepBy={onKeyDownStepBy}
          bgColor="black"
          overMaxValue={MAX_AGE_VALUE}
        />
        <Thumb
          value={state.value[1]}
          thumbIndex={1}
          thumbProps={thumbPropsSecondIndex}
          onKeyDownStepBy={onKeyDownStepBy}
          bgColor="black"
          overMaxValue={MAX_AGE_VALUE}
        />
      </chakra.div>
      <Spacer height={'4em'}></Spacer>
      <Flex flexDirection="row" justifyContent={'center'}>
        <NumberInput
          allowMouseWheel
          aria-label="Min Age"
          id="min-age-input"
          maxW="120px"
          value={
            state.value[0] === MAX_AGE_VALUE ? `100+` : `${state.value[0]}`
          }
          onChange={(valueAsString, valueAsNumber) =>
            handleNumberInputChange(0, valueAsNumber)
          }
        >
          <NumberInputField readOnly />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Flex alignItems="center" mx="2rem">
          -
        </Flex>
        <NumberInput
          allowMouseWheel
          aria-label="Max Age"
          id="max-age-input"
          maxW="120px"
          value={
            state.value[1] === MAX_AGE_VALUE ? `100+` : `${state.value[1]}`
          }
          onChange={(valueAsString, valueAsNumber) =>
            handleNumberInputChange(1, valueAsNumber)
          }
        >
          <NumberInputField readOnly />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Flex>
    </Box>
  );
}
