import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Spacer, Text } from '@chakra-ui/react';

export const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        minHeight: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box m={10} style={{ flexGrow: 1 }}>
        <Spacer height={'30px'}></Spacer>
        <Text textAlign={'left'} fontSize={'x-large'} fontWeight="semibold">
          {t('menu.about')}
        </Text>
        <Spacer height={'2em'}></Spacer>
        <Text textAlign={'left'} fontSize={'small'}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Odio eu
          feugiat pretium nibh ipsum. Tellus rutrum tellus pellentesque eu
          tincidunt tortor aliquam. Morbi tincidunt augue interdum velit euismod
          in pellentesque. Diam phasellus vestibulum lorem sed risus. Nisl vel
          pretium lectus quam id. Ipsum dolor sit amet consectetur adipiscing
          elit. Vitae auctor eu augue ut lectus arcu bibendum at varius. A cras
          semper auctor neque vitae. Mattis nunc sed blandit libero volutpat.
          Sed blandit libero volutpat sed cras ornare arcu dui vivamus. Nibh
          venenatis cras sed felis eget velit aliquet sagittis. Porttitor massa
          id neque aliquam. Eu volutpat odio facilisis mauris sit. Vitae nunc
          sed velit dignissim sodales. Sed risus pretium quam vulputate. Tellus
          id interdum velit laoreet. Posuere morbi leo urna molestie at.
          Elementum curabitur vitae nunc sed. Volutpat maecenas volutpat blandit
          aliquam etiam erat velit scelerisque in. Id neque aliquam vestibulum
          morbi blandit. In ornare quam viverra orci sagittis. Amet consectetur
          adipiscing elit pellentesque habitant morbi. Porttitor leo a diam
          sollicitudin tempor. Ultricies lacus sed turpis tincidunt id aliquet
          risus feugiat. Congue nisi vitae suscipit tellus mauris a diam
          maecenas sed. Vulputate mi sit amet mauris commodo. Sed ullamcorper
          morbi tincidunt ornare massa eget. Ac placerat vestibulum lectus
          mauris ultrices eros in cursus. Eget felis eget nunc lobortis mattis
          aliquam. Volutpat lacus laoreet non curabitur gravida. Felis eget nunc
          lobortis mattis aliquam faucibus. Eget gravida cum sociis natoque
          penatibus et magnis dis parturient. Ac tortor vitae purus faucibus
          ornare suspendisse sed nisi lacus. Eu turpis egestas pretium aenean.
          Magna eget est lorem ipsum dolor sit amet. Tellus at urna condimentum
          mattis pellentesque id nibh. Vitae turpis massa sed elementum tempus
          egestas sed. Sed ullamcorper morbi tincidunt ornare. Non enim praesent
          elementum facilisis leo. Sed arcu non odio euismod lacinia at quis
          risus sed. Netus et malesuada fames ac turpis egestas. Nulla at
          volutpat diam ut. Lectus nulla at volutpat diam ut venenatis. Eleifend
          donec pretium vulputate sapien nec. Eget nunc lobortis mattis aliquam
          faucibus purus in massa tempor. Lobortis mattis aliquam faucibus purus
          in massa. Fermentum iaculis eu non diam. Vel elit scelerisque mauris
          pellentesque pulvinar. Diam maecenas ultricies mi eget mauris pharetra
          et ultrices. Venenatis urna cursus eget nunc scelerisque viverra
          mauris in. Mi ipsum faucibus vitae aliquet. Velit laoreet id donec
          ultrices tincidunt arcu non sodales. Urna molestie at elementum eu
          facilisis. Integer enim neque volutpat ac tincidunt. Nibh cras
          pulvinar mattis nunc. Eget nulla facilisi etiam dignissim diam quis
          enim. Amet mattis vulputate enim nulla aliquet porttitor. Elementum
          nibh tellus molestie nunc. Phasellus faucibus scelerisque eleifend
          donec pretium vulputate sapien nec. In metus vulputate eu scelerisque
          felis imperdiet proin fermentum. Leo duis ut diam quam nulla porttitor
          massa id. Porttitor rhoncus dolor purus non enim praesent elementum
          facilisis leo. Arcu ac tortor dignissim convallis aenean et. Dictum
          non consectetur a erat nam at lectus. Blandit turpis cursus in hac.
        </Text>
        <Spacer height={'6em'}></Spacer>
      </Box>
    </div>
  );
};
