import React, { useEffect, useState } from 'react';
import { Image as ChakraImage, Skeleton } from '@chakra-ui/react';

interface TopTowerImageProps {
  imageUrls: string[];
}

const TopTowerImage: React.FC<TopTowerImageProps> = ({ imageUrls }) => {
  const [imageUrl, setImageUrl] = useState(imageUrls[0]);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const timeouts = imageUrls.map((url, index) =>
      setTimeout(() => {
        setImageUrl(url);
      }, index * 500),
    );

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, [imageUrls]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div
      style={{
        maxWidth: '800px',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {!imageLoaded && <Skeleton width="50%" height="auto" mt="4" />}
      <ChakraImage
        src={imageUrl}
        alt="Tokyo Tower probability meter"
        width="50%"
        height="auto"
        mt="4"
        onLoad={handleImageLoad}
        style={{ display: imageLoaded ? 'block' : 'none' }}
      />
    </div>
  );
};

export default TopTowerImage;
