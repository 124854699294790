export const formatProbability = (rawProbability: number) => {
  if (rawProbability === 0) {
    return '0';
  }

  if (!rawProbability) {
    return '0';
  }

  let precision = 0; // Default precision for numbers greater than or equal to 0.01
  let formatted = rawProbability.toFixed(precision);

  // Increase precision until non-zero digit is found or a reasonable limit is reached
  while (parseFloat(formatted) === 0 && precision < 10) {
    precision++;
    formatted = rawProbability.toFixed(precision);
  }

  return formatted;
};
