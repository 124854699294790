import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Spacer } from '@chakra-ui/react';

const lngs: {
  [key: string]: {
    nativeName: string;
  };
} = {
  en: {
    nativeName: 'English',
  },
  ja: {
    nativeName: '日本語',
  },
};

export default function LanguageSelector() {
  const { i18n } = useTranslation();
  const isSelected = (lng: string) => i18n.resolvedLanguage === lng;

  return (
    <Flex direction="column" alignItems="center">
      {Object.keys(lngs).map((lng, index) => (
        <React.Fragment key={lng}>
          <Button
            id={`language-selector-button-${lng}`}
            aria-label="Language Selector Button"
            size={'xs'}
            mr={2}
            key={lng}
            onClick={() => i18n.changeLanguage(lng)}
            disabled={i18n.resolvedLanguage === lng}
            variant={isSelected(lng) ? 'solid' : 'ghost'}
            colorScheme={isSelected(lng) ? 'primary' : 'gray'}
            color={isSelected(lng) ? 'black.500' : 'gray.500'}
            fontWeight="semibold"
          >
            {lngs[lng].nativeName}
          </Button>
          {index < Object.keys(lngs).length - 1 && <Spacer />}
        </React.Fragment>
      ))}
    </Flex>
  );
}
