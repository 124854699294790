import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import translation_en from './en.json';
import translation_ja from './ja.json';

const resources = {
  ja: {
    translation: translation_ja,
  },
  en: {
    translation: translation_en,
  },
};

const getBrowserLanguage = () => {
  const navigatorLanguage = navigator.language;
  return navigatorLanguage.split('-')[0];
};

const defaultLanguage = getBrowserLanguage();

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
