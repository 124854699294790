import React from 'react';
import { Box, Spinner } from '@chakra-ui/react';

const LoadingPage = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
    backgroundColor="white"
  >
    <Spinner size="xl" />
  </Box>
);

export default LoadingPage;
