import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Box, ChakraProvider, Flex, useDisclosure } from '@chakra-ui/react';
import { customTheme } from './chakraUI/theme';
import { backgroundColor } from './colorSchema';
import { About } from './components/About/About';
import { Calculator } from './components/Calculator/Calculator';
import { DrawerMenu } from './components/DrawerMenu';
import { Footer } from './components/Footer';
import { LandingPage } from './components/LandingPage/LandingPage';
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy';
import { Result } from './components/Result/Result';
import { Stats } from './components/Stats/Stats';
import { TopHeader } from './components/TopHeader';

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <ChakraProvider theme={customTheme}>
      <BrowserRouter>
        <Box position="fixed" top="0" left="0" right="0" zIndex="1">
          <TopHeader onOpen={onOpen} />
        </Box>
        <Box
          background="white"
          pt={{ base: '80px', lg: '100px' }}
          minHeight="100vh"
        >
          <Flex justify="center">
            <Box style={{ flex: 1 }} backgroundImage={backgroundColor}>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/calculator" element={<Calculator />} />
                <Route path="/stats" element={<Stats />} />
                <Route path="/about" element={<About />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/result" element={<Result />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Box>
            <DrawerMenu isOpen={isOpen} onClose={onClose} />
          </Flex>
        </Box>
      </BrowserRouter>
      <Footer />
    </ChakraProvider>
  );
}

export default App;
