import React from 'react';
import { IconType } from 'react-icons';
import { Button, Icon, Spacer } from '@chakra-ui/react';

type Props = {
  iconType: IconType;
  title: string;
  onClick: () => void;
};

export const CommonMenuButton = (props: Props) => (
  <Button
    w="100%"
    h="auto"
    px={6}
    py={3}
    variant="ghost"
    id={`${props.title}-button`}
    aria-label={props.title}
    onClick={props.onClick}
  >
    <Spacer />
    <Icon as={props.iconType} w={7} h={7} mr={2} />
    {props.title}
    <Spacer />
  </Button>
);
