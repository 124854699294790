import { FaCalculator } from 'react-icons/fa6';
import { IoMdBulb } from 'react-icons/io';
import { IoDocumentLock } from 'react-icons/io5';
import { MdBarChart } from 'react-icons/md';

export const MenuItems = [
  {
    name: 'menu.home',
    icon: FaCalculator,
    path: '/',
  },
  {
    name: 'menu.about',
    icon: IoMdBulb,
    path: '/about',
  },
  {
    name: 'menu.privacyPolicy',
    icon: IoDocumentLock,
    path: '/privacy-policy',
  },
  {
    name: 'menu.stats',
    icon: MdBarChart,
    path: '/stats',
  },
];
