import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Heading, Spacer, Stack, Text } from '@chakra-ui/react';
import { formatProbability } from '../../utils/formatProbability';

interface Props {
  label: string;
  attribute1: string | ReactNode | undefined;
  attribute2: string | ReactNode | undefined;
  value1: number;
  value2: number | undefined;
  value3: number | undefined;
}
export const Stat = (props: Props) => {
  const { label, attribute1, attribute2, value1, value2, value3, ...boxProps } =
    props;

  const { t } = useTranslation();

  // TODO: Text not aligned center

  return (
    <Box
      px={{ base: '6', md: '6' }}
      py={{ base: '5', md: '6' }}
      bg="bg.surface"
      borderRadius="lg"
      boxShadow="sm"
      {...boxProps}
    >
      <Stack textAlign="center">
        <Text fontSize="xs" textStyle="sm" color="fg.muted">
          {label}
        </Text>
        <Text pt="2" fontSize="md">
          {attribute1}
        </Text>
        {attribute2 && (
          <Text pt="2" fontSize="xs">
            {attribute2}
          </Text>
        )}
        <Spacer></Spacer>
        <Flex direction="column" align="center">
          <Flex direction="row" align="center">
            <Text pt="2" fontSize="xs">
              {t('calculator.wholePopulation')}
            </Text>
            <Heading size={{ base: 'sm', md: 'md' }} pl={value3 ? '2' : '0'}>
              {formatProbability(value1)}%
            </Heading>
          </Flex>
          <Spacer></Spacer>
          <Flex direction="row" align="center">
            {value2 && (
              <Text pt="2" fontSize="xs">
                {t('calculator.gender.female')}
              </Text>
            )}
            {value2 && (
              <Heading size={{ base: 'sm', md: 'md' }} pl={value3 ? '2' : '0'}>
                {formatProbability(value2)}%
              </Heading>
            )}
          </Flex>
          <Flex direction="row" align="center">
            {value3 && (
              <Text pt="2" fontSize="xs">
                {t('calculator.gender.male')}
              </Text>
            )}
            {value3 && (
              <Heading size={{ base: 'sm', md: 'md' }} pl={value3 ? '2' : '0'}>
                {formatProbability(value3)}%
              </Heading>
            )}
          </Flex>
        </Flex>
      </Stack>
    </Box>
  );
};
