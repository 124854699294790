import React, { useEffect, useState } from 'react';
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import Hexagon from './Hexagon';

interface HexagonGridProps {
  fillPercentage: number;
}

const HexagonGrid: React.FC<HexagonGridProps> = ({ fillPercentage }) => {
  const totalHexagons = 100;
  const filledHexagonsCount = Math.round(
    (fillPercentage / 100) * totalHexagons,
  );
  const [hexagons, setHexagons] = useState(Array(totalHexagons).fill(false));

  useEffect(() => {
    const newHexagons = [...hexagons];
    let filledCount = 0;

    while (filledCount < filledHexagonsCount) {
      const randomIndex = Math.floor(Math.random() * totalHexagons);
      if (!newHexagons[randomIndex]) {
        newHexagons[randomIndex] = true;
        filledCount++;
      }
    }

    setHexagons(newHexagons);
  }, [fillPercentage]);

  return (
    <Flex justify="center">
      <Box width="300px">
        <SimpleGrid columns={10} spacing={1}>
          {hexagons.map((filled, index) => (
            <Hexagon key={index} filled={filled} />
          ))}
        </SimpleGrid>
      </Box>
    </Flex>
  );
};

export default HexagonGrid;
