/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

type Props = {
  value: number;
  thumbIndex: number;
  thumbProps: any;
  bgColor: string;
  onKeyDownStepBy: (
    e: React.KeyboardEvent<HTMLDivElement>,
    thumbIndex: number,
  ) => void;
  overMaxValue: number;
};

const Thumb = ({
  value,
  bgColor,
  thumbIndex,
  thumbProps,
  onKeyDownStepBy,
  overMaxValue,
}: Props) => {
  return (
    <Box
      top="1%"
      boxSize={14}
      bgColor={bgColor}
      borderRadius="full"
      _focusVisible={{
        outline: 'none',
      }}
      onKeyDown={(e) => {
        onKeyDownStepBy(e, thumbIndex);
      }}
      {...thumbProps}
    >
      <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
        <Text color="white">
          {value === overMaxValue ? `${overMaxValue - 1}+` : value}
        </Text>
      </Flex>
    </Box>
  );
};

export default Thumb;
