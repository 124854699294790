import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';

const JAPANESE_POPULATION = 125_000_000; // 125 million

export const PopulationEstimateText: React.FC<{ probability: number }> = ({
  probability,
}) => {
  const { t } = useTranslation();
  const estimatedPopulation = (probability / 100) * JAPANESE_POPULATION;
  return (
    <Text fontSize="lg" mt={4}>
      {t('result.estimatedPopulation')}:{' '}
      {Math.round(estimatedPopulation).toLocaleString()} {t('result.people')}
    </Text>
  );
};
