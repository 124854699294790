import React from 'react';
import { Box } from '@chakra-ui/react';

interface HexagonProps {
  filled: boolean;
}

const Hexagon: React.FC<HexagonProps> = ({ filled }) => {
  return (
    <Box
      width="10px"
      height="10px"
      bg={filled ? 'blue.500' : 'gray.300'}
      clipPath="polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)"
    />
  );
};

export default Hexagon;
