export function convertToShortScale(language: string, number: number): string {
  if (number === 0) {
    return number.toLocaleString();
  }

  if (language === 'en') {
    const formattedNumber = number * 10000;

    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixNum = Math.floor(Math.log10(formattedNumber) / 3);
    let shortNum = formattedNumber / Math.pow(1000, suffixNum);

    if (shortNum % 1 !== 0) {
      shortNum = parseFloat(shortNum.toFixed(1));
    }

    return shortNum + suffixes[suffixNum];
  } else {
    return number.toLocaleString();
  }
}
