import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Stack } from '@chakra-ui/react';

type ChangeHandler = (selectedRegions: string[]) => void;

interface RegionCheckboxProps {
  onChange: ChangeHandler;
}

const regionNames = [
  'anywhere',
  'japan',
  'asia',
  'europe',
  'africa',
  'northAmerica',
  'southAmerica',
  'oceania',
];

const selectedRegions = (booleanList: boolean[]) =>
  booleanList.reduce((acc: string[], value, index) => {
    if (value) {
      acc.push(regionNames[index]);
    }
    return acc;
  }, []);

export default function RegionCheckbox({ onChange }: RegionCheckboxProps) {
  const [checkedItems, setCheckedItems] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);

  const allChecked = checkedItems.every(Boolean);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const handleCheckboxChange = (index: number) => {
    if (index === 0) {
      // If the "Everywhere" checkbox is clicked
      if (!isIndeterminate) {
        // If "Everywhere" is true, clear all other checkboxes
        const newCheckedItems = checkedItems.map((_, i) =>
          i === 0 ? true : false,
        );
        setCheckedItems(newCheckedItems);
        setIsIndeterminate(true);
        onChange(selectedRegions(newCheckedItems));
      } else {
        // If "Everywhere" is false, tick all checkboxes
        const newCheckedItems = Array(checkedItems.length).fill(true);
        setCheckedItems(newCheckedItems);
        setIsIndeterminate(false);
        onChange(selectedRegions(newCheckedItems));
      }
    } else {
      // If any other checkbox is clicked, toggle its value
      const updatedItems = [...checkedItems];
      updatedItems[index] = !updatedItems[index];
      setCheckedItems(updatedItems);
      setIsIndeterminate(
        updatedItems.some(Boolean) && !updatedItems.every(Boolean),
      );
      onChange(selectedRegions(updatedItems));
    }
  };

  const { t } = useTranslation();

  return (
    <Box
      m={10}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      fontSize={'lg'}
      textStyle="sm"
      color="fg.muted"
    >
      <Checkbox
        id="anywhere-checkbox"
        isChecked={allChecked}
        isIndeterminate={isIndeterminate}
        onChange={() => handleCheckboxChange(0)}
        variant="black"
      >
        {t('calculator.region.anywhere')}
      </Checkbox>
      <Stack pl={6} mt={1} spacing={1}>
        <Checkbox
          id="japan-checkbox"
          isChecked={checkedItems[1]}
          onChange={() => handleCheckboxChange(1)}
          variant="black"
        >
          {t('calculator.region.japan')}
        </Checkbox>
        <Checkbox
          id="asia-checkbox"
          isChecked={checkedItems[2]}
          onChange={() => handleCheckboxChange(2)}
          variant="black"
        >
          {t('calculator.region.asia')}
        </Checkbox>
        <Checkbox
          id="europe-checkbox"
          isChecked={checkedItems[3]}
          onChange={() => handleCheckboxChange(3)}
          variant="black"
        >
          {t('calculator.region.europe')}
        </Checkbox>
        <Checkbox
          id="africa-checkbox"
          isChecked={checkedItems[4]}
          onChange={() => handleCheckboxChange(4)}
          variant="black"
        >
          {t('calculator.region.africa')}
        </Checkbox>
        <Checkbox
          id="north-america-checkbox"
          isChecked={checkedItems[5]}
          onChange={() => handleCheckboxChange(5)}
          variant="black"
        >
          {t('calculator.region.northAmerica')}
        </Checkbox>
        <Checkbox
          id="south-america-checkbox"
          isChecked={checkedItems[6]}
          onChange={() => handleCheckboxChange(6)}
          variant="black"
        >
          {t('calculator.region.southAmerica')}
        </Checkbox>
        <Checkbox
          id="oceania-checkbox"
          isChecked={checkedItems[7]}
          onChange={() => handleCheckboxChange(7)}
          variant="black"
        >
          {t('calculator.region.oceania')}
        </Checkbox>
      </Stack>
    </Box>
  );
}
