import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Spacer, Text } from '@chakra-ui/react';
import LanguageSelector from './LanguageSelector/LanguageSelector';

type Props = {
  onOpen: () => void;
};

export const TopHeader = React.memo((props: Props) => {
  const navigate = useNavigate();

  return (
    <Flex
      as="header"
      top={0}
      width="100%"
      bg={'white'}
      shadow="sm"
      align="center"
      flexDirection="column"
      px={{ base: 6, lg: 6 }}
      py={{ base: 3, lg: 3 }}
      position="fixed"
      zIndex="999"
    >
      <Flex width="100%" align="center">
        <Box
          ml={{ base: 4, lg: 4 }}
          fontSize={{ base: 'xl', lg: '2xl' }}
          fontWeight="semibold"
          onClick={() => navigate('/')}
          cursor={'pointer'}
        >
          Test My Standard
        </Box>
        <Spacer />
        <LanguageSelector />
        <Button
          id="hamburger-menu-button"
          aria-label="Hamburger Menu"
          variant="ghost"
          fontSize={{ base: 'xl', lg: '3xl' }}
          boxSize={{ base: 8, lg: 16 }}
          onClick={() => props.onOpen()}
        >
          <HamburgerIcon />
        </Button>
      </Flex>
      <Box mt={2}>
        <Text fontSize="sm" color="red" textAlign="center">
          This website is still in development mode.
        </Text>
        <Text fontSize="sm" color="red" textAlign="center">
          Please expect changes!
        </Text>
      </Box>
    </Flex>
  );
});

TopHeader.displayName = 'TopHeader';
