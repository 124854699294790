import React from 'react';
import { Box, Text } from '@chakra-ui/react';

export const Footer = () => {
  return (
    <Box
      as="footer"
      p="4"
      fontSize={'x-small'}
      bg={'white'}
      color="black"
      textAlign="center"
      width="100%"
    >
      <Text>© Test My Standard Team. All rights reserved.</Text>
    </Box>
  );
};
