import { extendTheme } from '@chakra-ui/react';

export const customTheme = extendTheme({
  components: {
    Checkbox: {
      variants: {
        black: {
          control: {
            boxSize: 5,
            _checked: {
              bg: 'black',
              borderColor: 'black',
              color: 'white',
              _hover: {
                bg: 'black',
              },
            },
            _hover: {
              bg: 'white',
            },
            _indeterminate: {
              bg: 'black',
              _hover: {
                bg: 'black',
              },
            },
          },
          label: {
            color: 'black',
          },
        },
      },
    },
    Button: {
      baseStyle: {
        color: 'white', // Text color
      },
      variants: {
        black: {
          bg: 'black',
          _hover: {
            bg: 'black',
          },
          _loading: {
            bg: 'black',
            _hover: {
              bg: 'black',
            },
          },
          _disabled: {
            bg: 'gray',
            _hover: {
              bg: 'gray',
            },
          },
        },
      },
    },
  },
});
