import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillInstagram } from 'react-icons/ai';
import { MdFacebook } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { MenuItems } from '../MenuItems';
import { CommonMenuButton } from './CommonMenuButton';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const DrawerMenu = React.memo((props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onClickMenu = (path: string) => {
    navigate(path);
    props.onClose();
  };

  return (
    <Drawer placement="right" isOpen={props.isOpen} onClose={props.onClose}>
      <DrawerOverlay />
      <DrawerContent backgroundColor={'white'} style={{ zIndex: '998' }}>
        <DrawerBody px={0} py={6}>
          <Flex direction="column" alignItems={'flex-start'}>
            <CloseButton
              ml="270"
              onClick={props.onClose}
              fontSize="2xl"
              color="black"
              _hover={{ color: 'gray.900' }}
            ></CloseButton>
            {MenuItems.map((item) => (
              <Box key={item.name} w={'100%'}>
                <CommonMenuButton
                  iconType={item.icon}
                  title={t(item.name)}
                  onClick={() => onClickMenu(item.path)}
                />
              </Box>
            ))}
          </Flex>
          <Spacer height={'1em'}></Spacer>
          <Flex direction="row" justify={'center'}>
            <a href="https://www.facebook.com/profile.php?id=61555870135252">
              <Icon as={MdFacebook} w={7} h={7} mr={2} />
            </a>
            <Icon as={AiFillInstagram} w={7} h={7} mr={2} />
          </Flex>
          <Spacer height={'1em'}></Spacer>
          <Text align={'center'}>v0.0.6</Text>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
});

DrawerMenu.displayName = 'DrawerMenu';
